<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="search"
            codeGroupCd="COUNCIL_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            name="councilTypeCd"
            label="구분"
            v-model="searchParam.councilTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            label="기간"
            defaultStart="-6M"
            defaultEnd="6M"
            name="period"
            v-model="period"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="협력사 협의체 목록"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      rowKey="councilId"
      :columnSetting="false"
      :expandAll="true"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="등록" icon="add" @btnClicked="bookPopup" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
export default {
  name: 'council',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'councilTypeName',
            field: 'councilTypeName',
            label: '구분',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'councilTitle',
            field: 'councilTitle',
            label: '제목',
            align: 'left',
            type: "link",
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'councilDate',
            field: 'councilDate',
            label: '일자',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'regName',
            field: 'regName',
            label: '작성자/작성일',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          // {
          //   name: 'attach',
          //   field: 'attach',
          //   label: '관련 파일첨부',
          //   align: 'center',
          //   style: 'width:150px',
          //   type: 'attach',
          //   taskClassCd: 'SOP_COUNCIL',
          //   keyText: 'councilId',
          //   sortable: true,
          // },
        ],
        data: [],
      },
      searchParam: {
        plantCd: '',
        startYmd: '',
        endYmd: '',
        councilTypeCd: null,
      },
      period: [],
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'SOP_COUNCIL',
        taskKey: '',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.mdm.cim.vendor.council.list.url;
      this.getList();
    },
    getList() {
      if (this.period) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
        this.$_.forEach(this.grid.data, _item => {
          this.$set(this.attachInfo, 'taskKey', _item.councilId)
          this.$set(this.attachInfo, 'isSubmit', uid())
        })
      },);
    },
    linkClick(row) {
      this.bookPopup(row);
    },
    bookPopup(result) {
      this.popupOptions.target = () => import(`${"./vendorCouncilTab.vue"}`);
      this.popupOptions.title = '협력사 협의체 상세';
      this.popupOptions.param = {
        councilId: result ? result.councilId : '',
      };
      this.popupOptions.width = '95%';
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
